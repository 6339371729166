// 动环接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ColdMonitorlist: '/Cold_Monitor/Device/List',//设备列表
	GroupList: '/Cold_Monitor/ControlGroup/List',//控制组列表
	GroupAdd: '/Cold_Monitor/ControlGroup/Add',//添加控制组
	GroupPu: '/Cold_Monitor/ControlGroup/Pu',//添加控制组
	Groupdel: '/Cold_Monitor/ControlGroup/Delete',//添加控制组

	GroupDeviceAdd: '/Cold_Monitor/Device/Add',//添加设备
	GroupDevicePu: '/Cold_Monitor/Device/Pu',//编辑设备
	GroupDeviceDel: '/Cold_Monitor/Device/Delete',//编辑设备
	
	productList: '/Product/ProductType/List',//控制组列表
	
	deviceGets: '/Cold_Monitor/Device/Get',
	Devicepush: '/Device/Device/Push',

	Devicedelete: 'Cold_Monitor/Device/Delete'
	
	
	
}
export function Devicedelete(data) {
	return axios({
		url: ClassApi.Devicedelete,
		method: 'post',
		data:data,
	})
}
export function Devicepush(data) {
	return axios({
		url: ClassApi.Devicepush,
		method: 'post',
		data:data,
	})
}

export function GroupPu(data) {
	return axios({
		url: ClassApi.GroupPu,
		method: 'post',
		data:data,
	})
}
export function Groupdel(data) {
	return axios({
		url: ClassApi.Groupdel,
		method: 'post',
		data:data,
	})
}

export function deviceGets(data) {
	return axios({
		url: ClassApi.deviceGets,
		method: 'post',
		data:data
	})
}

export function GroupAdd(data) {
	return axios({
		url: ClassApi.GroupAdd,
		method: 'post',
		data:data,
	})
}
export function GroupDeviceDel(data) {
	return axios({
		url: ClassApi.GroupDeviceDel,
		method: 'post',
		data:data
	})
}
export function GroupDevicePu(data) {
	return axios({
		url: ClassApi.GroupDevicePu,
		method: 'post',
		data:data
	})
}

export function GroupDeviceAdd(data) {
	return axios({
		url: ClassApi.GroupDeviceAdd,
		method: 'post',
		data:data
	})
}

export function productList(data) {
	return axios({
		url: ClassApi.productList,
		method: 'post',
		data:data
	})
}
//编辑订单
export function ColdMonitorlist(data) {
	return axios({
		url: ClassApi.ColdMonitorlist,
		method: 'post',
		data:data
	})
}

//控制组列表
export function GroupList(data) {
	return axios({
		url: ClassApi.GroupList,
		method: 'post',
		data:data
	})
}