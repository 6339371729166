<!-- 全开全关 -->
<template>
    <div>
        <div class="drag" v-if="showDrag">
            <div class="drag-item" ref="scrollContainer">
                <div class="drag-item-it" v-for="item,index in dragData" :key="index">
                    <p style="font-size: 14px;">{{ item.name }}</p>
                    <p><i :class="!item.booles?'el-icon-close':'el-icon-check'" :style="{color: !item.booles?'red':''}"></i></p>
                </div>
                <div style="display: flex;align-items: center;justify-content: center;margin-top: 40px;">
                    <i class="el-icon-loading" v-if="loadings"></i>
                    <span style="font-size: 12px;padding-left: 10px;">{{ loadings?'正在发送指令，请稍后...':'--指令控制已完成--' }}</span>
                </div>
            </div>
        </div>
        <div class="drag" style="display: flex;justify-content: center;" v-else>
            <el-result icon="warning" data-aos="zoom-in" :data-aos-delay="0" title="批量操作" :subTitle="titleBox+'操作，是否立即执行'" style="color: #fff;"></el-result>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        titleBox:{
            type:String,
            default:''
        },
        loadings:{//加载中
            type:Boolean,
            default:true
        },
        showDrag:{
            type:Boolean,
            default:false
        },
        dragData:{
            type:Array,
            default:[]
        }
    },
    data(){
        return {
            list:[]
        }
    },
    watch:{
        dragData:{
            handler(news){
                this.scrollToBottom(); // 每次添加新消息后滚动到底部 
            },
            deep:true
        }
    },
    methods: {
        scrollToBottom() {  
            this.$nextTick(() => {  
                const scrollContainer = this.$refs.scrollContainer;  
                scrollContainer.scrollTop = scrollContainer.scrollHeight;  
            });  
        }, 
    },
};
</script>

<style scoped>
::v-deep .el-result__title p {
    color: #5fc5ff;
}
::v-deep .el-result .icon-success {
    fill: #5fc5ff;
}
::v-deep .el-result__subtitle p {
    color: #5fc5ff;
}
.drag {
    width: 450px;
    height: 300px;
    background: url(../../assets/img/bg-icon1.png) no-repeat;
    background-size: 100% 90%;
    background-position: center center;
    display: flex;
    grid-gap:20px;
    color:#5fc5ff;
    overflow: hidden;

}

.drag-item{
    overflow-y: auto;
    margin:60px 20px;
    flex: 1
}
</style>

<style>
    .drag-item-it{
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: .5px solid rgba(133, 170, 233, 0.08);
    }
</style>