import {
    ColdMonitorlist,
    GroupList,
    GroupAdd,
    deviceGets,
    GroupDeviceAdd,
    GroupDevicePu,
    Devicepush,
    Devicedelete
} from "@/api/MovingRingMonitoring/index.js";



export async function DevicedeleteFun(params) {
    return Devicedelete(params).then(res => {
        let reslut = res.data
        return reslut
    })
}
/**
* 修改Api
* @param {*} params 
*/
export async function DevicepushFun(params) {
    return Devicepush(params).then(res => {
        console.log('编辑',res)
        let reslut = res.data
        return reslut
    })
}

/**
* 修改Api
* @param {*} params 
*/
export async function setGroupDevicePuFun(params) {
    return GroupDevicePu(params).then(res => {
        console.log('编辑',res)
        let reslut = res.data
        return reslut
    })
}
/**
* 添加Api
* @param {*} params 
*/
export async function setGroupDeviceAddFun(params) {
    return GroupDeviceAdd(params).then(res => {
        console.log('编辑',res)
        let reslut = res.data
        return reslut
    })
}

/**
* 物联智控获取Api
* @param {*} params 
*/
export async function getdeviceGetFun(params) {
    return deviceGets(params).then(res => {
        let reslut = res.data
        return reslut
    })
}
/**
 * 添加控制组Api
 * @param {*} params 
 */
export async function setGroupAdd(params) {
    return GroupAdd(params).then(res => {
        console.log('添加控制组', res)
        // let reslut = res.data.Data || []
        // return reslut
    })
}
/**
 * 设备管理列表Api
 * @param {*} params 
 */
export async function getColdMonitorlist(params) {
    return GroupList({}).then(res => {
        let reslut = res.data.Data || []
        return reslut
    })
}

/**
 * 设备列表Api
 * @param {*} params 
 */
export async function getGroupList(params) {
    return ColdMonitorlist({}).then(res => {
        let reslut = res.data.Data || []
        return reslut
    })
}
