<template>
    <div class="stateTable">
        <div class="StateTable-container" style="opacity: 1; transition: all 0.2s ease 0.3s;">
            <div class="StateTable-content" style="opacity: 1; transform: none;">
                <div class="backgroundLine"></div>
                <div :class="actives != index ? 'item' : 'item-actives'" @click="setTabNum(key, index)"
                    v-for="key, index in list" :key='index'>
                    <div class="item-Line" v-show="actives == index"></div>
                    <div class="tableName">
                        <div style="">{{ key.T_tab }}</div>
                    </div>
                    <div class="clickBox"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        actives: {
            type: Number,
            default: 0
        },
        list: {
            type: Array,
            default: []
        }
    },
    methods: {
        setTabNum(item, index) {
            this.$emit('setTabfun', item)
            this.$emit('update:actives', index)
        }
    }
}
</script>
<style lang="scss">
.stateTable {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.StateTable-container {
    height: 60%;
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;

}

.StateTable-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.backgroundLine {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    background-color: #2f6bcf;
}

.item {
    position: relative;
    right: .25rem;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: #2f6bcf;
    cursor: pointer;
    transition: all .2s;
}

.item-actives {
    position: relative;
    right: .25rem;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: #5fc5ff;
    transition: all .2s;
    cursor: pointer;
    color: #5fc5ff !important;
}

.item-actives>.tableName {
    color: #5fc5ff !important;
}

.item:hover {
    color: #5fc5ff;
    background: #5fc5ff;
}

.tableName {
    //名称
    user-select: none;
    position: relative;
    min-width: 100px;
    top: -3.5px;
    right: 7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;
    /* 当文本溢出时显示省略号 */
    white-space: nowrap;
    /* 防止文本换行 */
    font-size: 12px;
    color: #2f6bcf;
}

.item-Line {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(.5rem + 6px);
    height: calc(.5rem + 6px);
    border-radius: 50%;
    box-shadow: 0 0 0 1px #5fc5ff;
    color: #5fc5ff;
}
</style>