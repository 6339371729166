<!-- 动环监控 -->
<template>
    <div class="rings">
        <my-Titlebar>
            <template slot="TitlebarLeft">
                <div class="rings-titLeft">
                    <!-- <span>数据：</span>
                    <label><span style="font-size: 40px;font-family: 'georgia'">12654</span><span> /条</span></label> -->
                </div>
            </template>
            <template slot="TitlebarRight">
                <div class="rings-titRight">
                    <my-btn text="添加组" @click="addGroup('添加组')"></my-btn>
                    <!-- <my-btn text="添加设备" @click="addEquipment('添加设备','add')"></my-btn> -->
                    <MovingModel :JsonArr="JsonArr" @confirm="confirmfun" @editstr="addEquipment" @addstr="addEquipment('添加设备','add')"></MovingModel>
                </div>
            </template>
        </my-Titlebar>
        <div class="rings-mins">
            <div class="rings-mins-right">
                <div class="rings-conters-nav" v-if="groupList.length!=0" data-aos="fade-right" data-aos-delay="0">
                    <div class="rings-conters-nav-item" v-for="item, index in groupList" :key="index" @click="clickall(item)" @contextmenu.prevent="handleRightClick($event,item)">
                        <div>{{item.T_name}}</div>
                    </div> 
                </div>
                <div class="rings-conters-table">
                    <div class="rings-conters-table-main">
                        <div class="rings-conters-table-main-flex">
                            <template v-for="item,index in filerList">
                                <div v-show="returnBoolean(item.d_show)"  @click.stop="addEquipment('编辑设备','edit',item)"
                                class="rings-conters-table-main-item">
                                    <div class="rings-conters-table-main-item-tit">
                                        {{ item[item.names]=='' || item[item.names]==undefined ?'=====':item[item.names]}}
                                    </div>
                                    <div class="rings-conters-table-main-item-conter">
                                        <div class="rings-conters-table-main-item-conter-item" @click.stop="setState(item)">
                                            <div class="rings-conters-table-main-item-conter-text">
                                                {{ item.T_online != 1 ? '离线': item[item.swiperName]==1?'开启': item[item.swiperName]==0?'关闭':'执行失败'}}
                                            </div>
                                            <div class="rings-conters-table-main-item-conter-img">
                                                <img src="../../assets/img/btn.png" style="width: 100%;height: 100%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <stateTable @setTabfun="setTabfun" :actives.sync="setTabactives" :list="JsonArr"></stateTable>
                </div>
            </div>
        </div>
        <my-Modalbox :visible.sync="visible" :titleBox="titleBox" iswidth="50%">
            <div slot="modalConter" class="ring-model-groups">
                <groups :tableData.sync="tableData" :formData.sync="formData" @updataFun="updataFun" :titleBox="titleBox" :renderArr="renderArr"></groups>
            </div>
        </my-Modalbox>

        <!-- //添加设备/编辑设备 -->
        <my-Modalbox :visible.sync="stops" style="z-index: 10000 !important;" :titleBox="titleBox" iswidth="50%" minHeihts="650px" isheight="650px">
            <div slot="modalConter" class="ring-model-main">
                <Slides :showInput="titleBox" :form.sync="form"></Slides>
                <div class="ring-model-btn" @click="onSubmit">
                    <img src="../../assets/img/btn.png">
                    <label>立即提交</label>
                </div>
            </div>
        </my-Modalbox>
        <!-- //全开，全关 -->
        <my-Modalbox :visible.sync="isall" :titleBox="titleBox" iswidth="40%" minHeihts="500px" isheight="550px">
            <div slot="modalConter" class="ring-model-main">
                <alloffon :showDrag.sync="showDrag" :dragData="dragData" :titleBox="titleBox" :loadings='loadings'></alloffon>
                <div class="ring-model-btn" @click="showDragFun">
                    <img src="../../assets/img/btn.png">
                    <label>立即操作</label>
                </div>
            </div>
        </my-Modalbox>
    </div>
</template>

<script>

import Slides from './Slides.vue'
import stateTable from "./stateTable.vue";
import alloffon from "./alloffon.vue";
import groups from "./groups.vue";
import MovingModel from "./modules/MovingModel.vue";

 import {
    getGroupList,
    getColdMonitorlist,
    setGroupAdd,
    getdeviceGetFun,
    setGroupDeviceAddFun
} from "./js/MovingRingApi.js";

import {MovingFun} from "./js/MovingData.js";
import {
    GroupDevicePu,
} from "@/api/MovingRingMonitoring/index.js";
export default {
    components:{stateTable,Slides,alloffon,groups,MovingModel},
    data() {
        return {
            //组
            renderArr:[],//渲染所有设备
            tableData:[],
            formData:{},//参数
            //单控
            singleIndex:0,//单控次数
            singleControl:false,//单控状态
            singleData:{},//单控发送web的数据格式
            //
            loadings:false,//全控加载图标
            dragData:[],//全开关的展示内容
            thisIndex:0,//当前发送的数据索引
            iswebData:null,//当前正在进行发送的数据
            msgTime:null,//定时器
            sendData:[],//当前需要全开全关的数组
            IfwebSocket:false,//判断是否发送成功
            isObjket:{},
            form: {//添加设备
                T_sn: '',//设备编号
                T_tab: '',//分组名称
                //设备名称根据返回的"d_name"或name自动生成
            },
            socket: null,
            groupList:[],//组
            JsonArr:[],//设备列表
            showDrag:false,
            isall:false,//全开全关
            stops:false,//添加设备
            setTabactives:0,//设置tab的值
            visible: false,
            titleBox: '',
            rules: {
                T_sn: [
                    { required: true, message: '请输入设备编号', trigger: 'blur' }
                ],
                T_tab: [
                    { required: true, message: '请输入设备标签', trigger: 'blur' }
                ],
                d_name: [
                    { required: true, message: '请输入设备名称', trigger: 'blur' }
                ],
            }
        }
    },
    computed:{
        filerList(){
            if(this.JsonArr[this.setTabactives]!=undefined){
                // console.log('1111123',this.JsonArr[this.setTabactives].JsonData)
                return this.JsonArr[this.setTabactives].JsonData.T_dataJson
            }else{
                return []
            }
        },
    },
    watch:{
        JsonArr:{
            handler(newData,oldData){
                // console.log('数据变化',newData,oldData,this.socket)
                if(oldData.length==0 && newData.length!=0){//老数据从0开始了可以订阅了
                    if(this.socket!=null){
                        this.close()//关闭socket
                    }
                    this.init()
                }
            },
            deep:true,
        },
        async IfwebSocket(newData){
            this.loadings = true
            let obj = JSON.parse(this.sendData.T_text)//当前需要控制的数据
            if (newData) {//进行中
                this.iswebData = obj[this.thisIndex]
                for (let j = 0; j < 3; j++) {
                    const setData = await this.setDataFn(obj[this.thisIndex])//处理发送到webSocket的数据格式
                    this.send(setData)
                    await new Promise(resolve => this.msgTime = setTimeout(resolve, 3000));//3s
                    if(j==2 && newData==true){//执行失败，进入下一次
                        this.IfwebSocket = false
                        clearTimeout(this.msgTime)
                        this.msgTime = null
                        this.dragData.push({name:this.iswebData[this.iswebData.names]==undefined?'未定义名称':this.iswebData[this.iswebData.names],booles:false})
                    }
                }
            }else{//完成
                if(this.thisIndex < obj.length-1){//小于还未发完
                    this.IfwebSocket = true
                    this.thisIndex += 1
                }else{//发送完成改成0
                    this.thisIndex = 0
                    this.loadings = false
                    this.iswebData = null
                }
                clearTimeout(this.msgTime)
                this.msgTime = null
            }
        },
        async singleControl(newData){
            if(newData){//进行中
                for (let j = 0; j < 3; j++) {
                    this.send(this.singleData)
                    this.singleIndex=j
                    await new Promise(resolve => this.msgTime = setTimeout(() => resolve(),3000))
                    if(j==2 && newData==true){//执行失败，进入下一次
                        this.$message.error('指令执行失败')
                        this.singleControl = false
                    }else{
                        continue
                    }
                }
            }else{//成功
                this.singleControl = false
                if(this.singleIndex==2){
                    this.$message.error('执行失败')
                }else{
                    this.$message.success('执行成功')
                }
                clearTimeout(this.msgTime)
                this.msgTime = null
                this.singleIndex = 0
                this.loadingRes.close()
            }
        },
    },
    created() {
        this.getGroupListApi()//获取组
        this.getgetGroupListApi()//获取设备列表
    },
    methods: {
        returnBoolean(value){  
            if(value=="true" || value==true){
                return Boolean(true)
            }else{
                return Boolean(false)
            }
        },
        confirmfun(){
            console.log('确定')
            this.getgetGroupListApi()//获取设备
        },
        addEquipment(tit, tab) {//添加或者编辑触发按钮
            this.stops = true
            this.titleBox = tit
            this.isObjket = tab
            // let isArr = [...this.JsonArr]
            console.log('编辑',tit, tab)

            if(tit=='编辑设备'){
                // this.$refs.MovingRingRef.form.T_sn = tab.JsonData.T_sn
                // this.$refs.MovingRingRef.form.T_tab = tab.T_tab
                // const b = isArr.find(item => item.T_sn[0]==obj.T_sn)
                this.form.T_sn = tab.JsonData.T_sn
                this.form.T_tab = tab.T_tab
                // this.$set(this.form,obj.names, obj[obj.names]==undefined?'':obj[obj.names])
            }else{
                Object.keys(this.form).forEach(item=>{
                    this.form[item] = ''
                })
            }
        },
        async onSubmit(){//添加编辑提交按钮
            if(this.titleBox=='添加设备'){
                let {T_sn, T_tab} = {...this.form}
                let initPomes = {T_sn, T_tab}
                const res = Object.keys(initPomes).every(item => initPomes[item]!='')
                if(res){
                    const reslut = await setGroupDeviceAddFun(initPomes)//添加api
                    if (reslut.Code==200) {
                        this.$message.success('添加成功')
                        this.stops = false

                        let obj = {
                            T_sn: [initPomes.T_sn],
                            T_tab: initPomes.T_tab,
                            JsonData:{}
                        }
                        
                        const getRes = await this.getdeviceGetFunApi(initPomes.T_sn)
                        console.log('添加成功，订阅2',getRes)
                        if(getRes.Code==200){
                            obj.JsonData = getRes.Data || []
                            this.JsonArr.push(obj)
                            this.send({
                                type: 1,
                                sn: getRes.Data.T_sn,
                                pass: getRes.Data.T_password,
                                json: {}
                            })
                        return

                        }
                        
                        return
                    } else {
                        this.$message.error(reslut.Msg)
                        return
                    }
                }else{
                    this.$message.error('哎呀！所有项都为必填项哦！')
                }
            }else if(this.titleBox=='编辑设备'){
                let initPomes = {...this.form}
                const res = Object.keys(initPomes).every(item => initPomes[item]!='')
                if(res){
                    let objs = {
                        type: 3,
                        sn:this.isObjket.T_sn,
                        pass:this.isObjket.T_password,
                        json: {},
                    }
                    var obj2 = {}
                    this.$set(obj2, this.isObjket.names, this.form[this.isObjket.names])
                    this.$set(objs.json, this.isObjket.keys, obj2)
                    this.send(objs);//先发送给websocket
                    this.setGroupDevicePu(initPomes)

                }else{
                    this.$message.error('哎呀！所有项都为必填项哦！')
                }
            }
        },
        //编辑设备Api
        setGroupDevicePu(obj) {
            GroupDevicePu(obj).then(res => {
                console.log('编辑设备', res)
                if (res.data.Code == 200) {
                    this.$message.success('编辑成功')
                    this.stops = false
                    this.getgetGroupListApi()
                }
            })
        },
        updataFun(data){//更新组之后
            console.log('更新组',data)
            this.visible = false
            this.getGroupListApi()
        },
        async getGroupListApi(){//获取控制组
            const reslut = await getColdMonitorlist({})
            this.groupList = reslut
        },
        async getgetGroupListApi(){//获取设备列表
            const reslut = await getGroupList({})
            let arr = [...reslut]
            let setArr = []

            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr[i].T_sn.length; j++) {
                    const res = await this.getdeviceGetFunApi(arr[i].T_sn[j])
                    if(res.Code==200){
                        setArr.push({...arr[i],JsonData:res.Data})
                    }
                }
            }

            this.JsonArr = setArr
            // console.log('获取设备列表1111',this.JsonArr)
        },
        async getdeviceGetFunApi(sn){//通过sn循环获取设备
            return new Promise(resolve=>{
                getdeviceGetFun({T_sn:sn}).then(res=>{
                    setTimeout(() => {
                        console.log('----',res)
                        if (res.Code==200) {
                            const reslut = MovingFun(res)
                            resolve(reslut)
                        }else{
                            resolve(res)
                        }
                    }, 200);
                })
            })

        },
        
        setState(item){//单个控制-发送指令
            this.loadingRes = this.$loading({
                lock: true,
                text: '正在发送控制指令，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.singleControl = true //单控发送中状态
            this.singleData = {
                type: 3,
                sn: item.T_sn,
                pass: item.T_password,
                json: {},
            }
            var obj2 = {}
            this.$set(obj2, item.swiperName, item[item.swiperName] == 1 ? 0 : 1)
            this.$set(this.singleData.json, item.keys, obj2)
            
        },
        /********************控制3s */
        async showDragFun(){//确认执行按钮
            this.showDrag = true
            this.IfwebSocket = true//改变发送数据状态
            this.dragData = []
        },
        setDataFn(key){//处理传socket的数据格式
            return new Promise(reslove=>{
                let obj = {
                    type: 3,
                    sn: key.T_sn,
                    pass: key.pass || key.T_password,
                    json: {},
                }
                var obj2 = {}
                this.$set(obj2, key.swiperName, key[key.swiperName])
                this.$set(obj.json, key.keys, obj2)
                reslove(obj)
            })
        },
        /********************控制3s结束 */
        clickall(data){//点击全开全关按钮
            this.sendData = data
            this.titleBox = data.T_name
            this.isall = true
            this.showDrag = false
        },
        handleRightClick(event,item) {//右键编辑组
            event.preventDefault();  
            this.visible = true
            this.titleBox = '编辑组'
            this.formData = {
                Id: item.Id,
                T_name:item.T_name,
                T_text: item.T_text
            }
            console.log('text',item)
            this.tableData = item.T_text==""?[]:JSON.parse(item.T_text)
            let arr = [...this.JsonArr]
            let newArr = arr.map(item => item.JsonData.T_dataJson);  
            this.renderArr = newArr.flat()
        },
        setTabfun(data) { 
            console.log('设置值',data)
        },
        
        addGroup(item) {
            this.visible = true
            this.titleBox = item
            this.formData = {
                Id: item.Id,
                T_name:item.T_name,
                T_text: ''
            }
            this.tableData = []

            let arr = [...this.JsonArr]
            let newArr = arr.map(item => item.JsonData.T_dataJson);  
            this.renderArr = newArr.flat()
        },

        /************************************连接websocket */
        //websocket
        init() {
            if (typeof (WebSocket) === "undefined") {
                alert("您的浏览器不支持socket")
            } else {
                // 实例化socket
                this.socket = new WebSocket("wss://cold.coldbaozhida.com/Cold_Monitor_AOIT/WS")
                // this.socket = new WebSocket("wss://uat.sssyin.cn/ws-reservation")
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
                //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                window.onbeforeunload = () => {
                    this.socket.onclose = this.close
                };
                return this.socket
            }
        },
        open() {
            let that = this
            this.loadingRes = this.$loading({
                lock: true,
                text: '正在订阅中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            console.log('订阅',this.JsonArr)
            for (let i = 0; i < that.JsonArr.length; i++) {
                // console.log('开始连接1',that.ColdMonitorList[i])
                for (let j = 0; j < that.JsonArr[i].T_sn.length; j++) {
                    // console.log("socket连接成功",that.ColdMonitorList[i].arrs[0].T_sn)
                    that.send({
                        type: 1,
                        sn: that.JsonArr[i].T_sn[0],
                        pass: that.JsonArr[i].JsonData.T_password,
                        json: {}
                    })
                }
            }
            this.loadingRes.close()
        },

        error(error) {
            console.log("连接错误", error)
            if (this.socket) this.close()
            this.init()
        },

        getMessage(msg) {
            // 普通推送——正常处理
            let reslut = JSON.parse(msg.data)
            // console.log('接收消息',reslut,this.JsonArr)
            if(reslut.T_sn!=undefined){//返回消息是控制后返回的数据
                let isArr = [...this.JsonArr]

                if (reslut.T_sn!=undefined) {
                    //单控
                    if(this.singleControl){//单控进行中
                        if(this.singleData.sn==reslut.T_sn){
                            this.singleControl = false
                        }
                    }
                    //全开全关时改变状态
                    if (this.iswebData!=null) {
                        if( reslut.T_sn==this.iswebData.T_sn){
                            this.IfwebSocket = false
                            clearTimeout(this.msgTime)
                            this.msgTime = null
                            this.dragData.push(//全控成功
                                {name:this.iswebData[this.iswebData.names]==undefined?'未定义名称':this.iswebData[this.iswebData.names],booles:true})
                        }
                    }
                }
                
                for (let index = 0; index < isArr.length; index++) {//处理更名之后没有名称
                    if(isArr[index].T_sn[0]==reslut.T_sn){
                        for (const key in reslut) {
                            let setArr = isArr[index].JsonData.T_dataJson
                            for (let p = 0; p < setArr.length; p++) {
                                if(key==setArr[p].keys){
                                    this.$set(reslut[key],setArr[p].names,setArr[p][setArr[p].names])
                                }
                            }
                        }
                        break;
                    }
                }
                

                const b = isArr.find(item => item.T_sn[0]==reslut.T_sn)
                b.JsonData.T_dataJson = reslut
                let data = {
                    Data:b.JsonData
                }
                const j = MovingFun(data)
                const index = isArr.findIndex(item => item.T_sn[0] == j.Data.T_sn)
                this.JsonArr[index].JsonData = j.Data
            }else{
                console.log('其他消息',this.JsonArr)
            }
            this.loadingRes.close()

        },

        // 发送消息给被连接的服务端
        send(params) {
            // console.log('发送消息', params)
            this.socket.send(JSON.stringify(params))
        },

        /**
         * 关闭socket
         */
        close() {
            console.log("socket已经关闭")
            clearInterval(this.setIntTime)
            this.socket.onclose = close
            this.socket.onopen = open
        }
    }
}
</script>
<style scoped>
::v-deep .el-tabs__item {
    color: #5fc5ff;
}

::v-deep .el-tabs__item.is-active {
    color: #5fc5ff !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: #183d7d;
}

::v-deep .el-form-item__label {
    color: #5fc5ff;
}

::v-deep .el-input__inner {
    background-color: #183d7d;
    border-radius: 4px;
    border: 1px solid #183d7d;
    color: #fff;
}

::v-deep .el-switch.is-checked .el-switch__core {
    border-color: #183d7d;
    background-color: #183d7d;
}

::v-deep .el-switch__core {
    border: 1px solid #f56c6c;
    background: #f56c6c;
}

::v-deep .el-radio {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
}
</style>
<style lang="scss">
@import url('../../assets/scss/rings.scss');
</style>