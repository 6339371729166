<template>
    <div>
        <div class="drag">
            <el-input v-model="form.T_sn" :disabled="showInput=='编辑设备'?true:false" @change="inputChange" style="width: 300px;" placeholder="请输入设备编号"></el-input>
            <!-- <el-input maxlength="7" v-model="form.d_name" @change="inputChange" v-if="showInput=='编辑设备'" style="width: 300px;" placeholder="请输入设备名称"></el-input> -->
            <el-input maxlength="7" v-model="form.T_tab" @change="inputChange" style="width: 300px;" placeholder="请输入设备标签"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        showInput:{
            type:String,
            default:''
        },
        form: {//添加设备
            type:Object,
            default:{
                T_sn: '',//设备编号
                T_tab: '',//分组名称
            }
        },
    },
    data() {
        return {
            
        };
    },
    methods: {
        inputChange(){
            this.$emit('update:form',this.form);
            console.log('失去焦点')
        },
    },
};
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #183d7d;
    border: 1px solid #183d7d;
    color: #fff;
    cursor: not-allowed;
}
::v-deep .el-result__title p {
    color: #5fc5ff;
}
::v-deep .el-result .icon-success {
    fill: #5fc5ff;
}
::v-deep .el-result__subtitle p {
    color: #5fc5ff;
}
.drag {
    width: 515px;
    height: 400px;
    margin: 0 auto;
    background: url(../../assets/img/icon-6.png) no-repeat;
    background-size: 100% 90%;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap:20px;
    color:#5fc5ff;
}

.drag-item {
    font-size: 30px;
}
.drag-item:nth-child(1){
    transform: rotate(15deg);
}
.drag-item:nth-child(3){
    transform: rotate(-30deg);
}
.drag-item-kuang {
    width: 100px;
    height: 40px;
    outline: none;
    background: none;
    text-align: center;
    font-size: 30px;
    color:#5fc5ff;
    border: none;
    border: .5px solid rgba(133, 170, 233, 0.5);
    transition: all .2s;
}
.drag-item-kuang:focus{
    border-radius: 20px;
    color:#5fc5ff;
}
</style>