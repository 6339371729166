<!-- 添加组 -->
<template>
    <div class="groups">
        <el-form ref="dynamicValidateForm" :model="formData" label-width="80px">
            <el-form-item label="组名称" prop="T_name" :rules="[{ required: true, message: '请输入组名称', trigger: 'blur' }]">
                <el-input v-model="formData.T_name"></el-input>
            </el-form-item>
            <el-form-item label="控制设备">
                <el-button type="primary" icon="el-icon-plus" @click="showoutVisFun">添加设备</el-button>
            </el-form-item>
            <el-form-item>
                <el-table :data="tableData" style="width: 100%" height="300px">
                    <el-table-column prop="date" label="设备名称" width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{ scope.row[scope.row.names] == undefined ? '--' : scope.row[scope.row.names] }}
                        </template>
                    </el-table-column>
                    <el-table-column label="开/关" width="180">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row[scope.row.swiperName]" active-color="#03aff6" inactive-color="#ff4949" 
                            @change="changeFun" :active-value="1" :inactive-value="0">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="btnDel" @click="delClick(scope.row, scope.$index)"> 删除 </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: center;margin-top: 80px;">
            <el-button type="danger" @click="onSubdelect" v-if="this.titleBox!='添加组'">删除组</el-button>
            <el-button type="primary" @click="onSubmit">立即提交</el-button>
        </div>
        <el-dialog title="添加设备" :visible.sync="outerVisible" :append-to-body="true" width="30%">
            <el-table :data="renderArr" style="width: 100%" ref="multipleTable"
            type="selection"
            @selection-change="handleSelectionChange" max-height="600px">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="date" label="设备名称" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ scope.row[scope.row.names] == undefined ? '--' : scope.row[scope.row.names] }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { GroupPu,GroupAdd,Groupdel} from "@/api/MovingRingMonitoring/index"
export default {
    props: {
        titleBox: {
            type: String,
            default:''
        },
        tableData: {
            type: Array,
            default: []
        },
        formData: {
            type: Object,
            default: {
                Id: null,
                T_name: '',
                T_text: []
            }
        },
        renderArr:{//渲染所有列表
            type: Array,
            default: []
        },
    },
    data() {
        return {
            outerVisible: false,
            initParam: {
                page: 1,
                page_z: 3,
                name: ''
            },
        }
    },
    methods: {
        async onSubdelect(){
            console.log('删除',this.formData)
            const reslut = await Groupdel({Id:this.formData.Id})
            if(reslut.data.Code==200){
                    this.$message.success('删除成功')
                    this.$emit('updataFun')
                }
        },
        async onSubmit(){
            const booles = Object.keys(this.formData).every(item => this.formData[item]!='')
            if (booles) {
                let reslut = null
                if(this.titleBox=='添加组'){
                    reslut = await GroupAdd(this.formData)
                }else{//编辑组
                    reslut = await GroupPu(this.formData)
                }
                console.log('组',reslut)
                if(reslut.data.Code==200){
                    this.$message.success(this.titleBox=='添加组'?'添加成功':'编辑成功')
                    this.$emit('updataFun')
                }
            } else {
                this.$message.error('所有项为必填项哦')
                return
            }
            console.log('提交',booles,this.formData,JSON.parse(this.formData.T_text))
        },
        changeFun(){
            let formObj = {...this.formData}
            formObj.T_text = JSON.stringify(this.tableData)
            this.$emit('update:tableData',this.tableData)
            this.$emit('update:formData',formObj)
        },
        showoutVisFun(){
            this.outerVisible = true
            // console.log('添加',this.tableData,this.renderArr)
            this.$nextTick(()=>{
                this.setDefaultSelection()

            })
            // this.$refs.multipleTable.toggleRowSelection(this.tableData)
        },
        // 在数据加载完毕后，设置默认选中的行  
        setDefaultSelection() {  
            let that = this
            const multipleTable = this.$refs.multipleTable;  
            const rows = this.renderArr;  
            // // 遍历所有的行数据，并找到需要默认选中的行
            for (let i = 0; i < rows.length; i++) {
                if(that.tableData.length==0){multipleTable.toggleRowSelection(rows[i],false); continue;}//没有选择的全部取消
                for (let j = 0; j < that.tableData.length; j++) {
                    if (that.tableData[j].keys == rows[i].keys) {  
                        multipleTable.toggleRowSelection(rows[i],true);  
                        break;
                    }else{
                        multipleTable.toggleRowSelection(rows[i],false);  
                        continue
                    }
                }
            }
        },  
        handleSelectionChange(val) {
            this.$emit('update:tableData',val)
            let formObj = {...this.formData}
            formObj.T_text = JSON.stringify(val)
            this.$emit('update:formData',formObj)
        },
        //删除
        delClick(row, index) {
            this.tableData.splice(index, 1);

            let formObj = {...this.formData}
            if(this.tableData.length!=0){
                formObj.T_text = JSON.stringify(this.tableData)
                this.$emit('update:tableData',this.tableData)
            }else{
                formObj.T_text = ''
                this.$emit('update:tableData',[])
            }
            this.$emit('update:formData',formObj)
        },
    }
}
</script>
<style scoped>
::v-deep .el-dialog__header {
    padding: 20px 20px 10px;
    background-color: rgb(8, 44, 95);
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

::v-deep .el-dialog__title {
    color: #03aff6;
}

::v-deep .el-dialog__body {
    color: #03aff6;
    background-color: rgb(8, 44, 95);
}

::v-deep .el-dialog__footer {
    background-color: rgb(8, 44, 95);
}
</style>
<style scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    border-bottom: 1px solid rgba(133, 170, 233, 0.08);
}

::v-deep .el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(133, 170, 233, 0.08) !important;
}

/* 设置 el-table 的背景色为透明 */
::v-deep .el-table {
    background-color: transparent !important;
    border:1px solid rgba(133, 170, 233, 0.1);
}

/* 设置 el-table 的表头背景色 */
::v-deep .el-table__header-wrapper th {
    background-color: rgba(133, 170, 233, 0.08) !important;
    color: #03aff6;
}

::v-deep .el-form-item__content {
    line-height: 0;
}

::v-deep .el-table tr {
    background-color: transparent !important;

}

/* 设置表格行的背景色为透明 */
::v-deep .el-table__body tr {
    background-color: transparent !important;
    color: #03aff6;
}

/* 如果需要，也可以设置鼠标悬停时的行背景色为透明 */
::v-deep .el-table__body tr:hover>td {
    background-color: rgba(133, 170, 233, 0.08) !important;
}

::v-deep .el-table__cell {
    /* border-bottom: 1px solid rgba(133, 170, 233, 0.08); */
}


/**去掉table底部的横线 */
::v-deep .el-table::before {
    height: 0;
}


::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 1px solid #03aff6;
}

/* 修改勾选框的伪元素颜色（如果需要） */
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border-color: #03aff6;
    /* 勾选标记的颜色 */
}
</style>

<style lang="scss">
/* @import url(); 引入css类 */
.groups {
}

.btnDel {
    border: 1px solid #03aff6;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    color: #03aff6;
    cursor: pointer;
    transition: all .2s;
}

.btnDel:hover {
    transform: scale(.95);
}
</style>